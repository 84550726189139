import * as React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import * as styles from './policy.module.scss';

const PolicySidebar = ({ intl: { formatMessage } }) => {
  return (
    <div className={styles.containerPolicy}>
      <title>Politicas Sidebar</title>
      <header>
        <h1 className={styles.containerPolicy__title}>
          {formatMessage({ id: 'politics' })}
        </h1>
      </header>
      <main>
        <section>
          <h2 className={styles.containerPolicy__subtitle}>
            {formatMessage({ id: 'infoGeneral' })}
          </h2>
          <p className={styles.containerPolicy__text}>
            {formatMessage({ id: 'textGeneral' })}
          </p>
        </section>

        <section>
          <h2 className={styles.containerPolicy__subtitle}>
            {formatMessage({ id: 'useData' })}
          </h2>
          <p className={styles.containerPolicy__text}>
            {formatMessage({ id: 'textData' })}
          </p>
        </section>

        <section>
          <h2 className={styles.containerPolicy__subtitle}>
            {formatMessage({ id: 'allowsExtension' })}
          </h2>
          <ul>
            <li>
              <strong>activeTab</strong>: {formatMessage({ id: 'activeTab' })}
            </li>
            <li>
              <strong>contextMenus</strong>:{' '}
              {formatMessage({ id: 'contextMenus' })}
            </li>
            <li>
              <strong>storage</strong>: {formatMessage({ id: 'storage' })}
            </li>
            <li>
              <strong>tabs</strong>: {formatMessage({ id: 'tabs' })}
            </li>
            <li>
              <strong>sidePanel</strong>: {formatMessage({ id: 'sidePanel' })}
            </li>
          </ul>
        </section>
      </main>

      <footer className={styles.containerPolicy__footer}>
        &copy; 2024 Connecting Tech People.{' '}
        {formatMessage({ id: 'allRightsReserved' })}
      </footer>
    </div>
  );
};

export default injectIntl(PolicySidebar);

PolicySidebar.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};
