// extracted by mini-css-extract-plugin
export var body_background = "#f8f9fa";
export var containerPolicy = "policy-module__containerPolicy___2tCg3";
export var containerPolicy__content = "policy-module__containerPolicy__content___qmdsH";
export var containerPolicy__footer = "policy-module__containerPolicy__footer___q3upg";
export var containerPolicy__subtitle = "policy-module__containerPolicy__subtitle___nDXcy";
export var containerPolicy__text = "policy-module__containerPolicy__text___7pWFJ";
export var containerPolicy__title = "policy-module__containerPolicy__title___YBeAQ";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var lg = "1200px";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "#2ed3ae";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var white = "#fff";
export var xl = "1536px";
export var xxl = "2500px";